// An abstraction around session/local storage
//
// If session/local storage is available then we use on of this. If the uses has
// asked to be "remembered" then we choose localStorage. If these are not
// available we use an InProcStore which will only persist until another page
// load happens.

function isLocalStorageNameSupported() {
  const testKey = 'testLocalStorageNameSupported';
  try {
    const storage = window.localStorage;
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

const CookieStore = {
  getItem(key) {
    const item = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
    return item ? item.pop() : null;
  },
  setItem(key, value) {
    document.cookie = `${key}=${value}`;
  },
  removeItem(key) {
    document.cookie = `${key}=`;
  },
};

const BrowserStorage = {
  getItem(key) {
    return window.localStorage.getItem(key);
  },
  setItem(key, value) {
    return window.localStorage.setItem(key, value);
  },
  removeItem(key) {
    return window.localStorage.removeItem(key);
  },
};

export default (isLocalStorageNameSupported() ? BrowserStorage : CookieStore);
