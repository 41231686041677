import storage from 'utils/storage';

function setLanguage(language) {
  if (language === 'fr-CA') {
    window.Transifex.live.translateTo('fr_CA', true);
    document.getElementById('lang-fr').setAttribute('data-active', true);
    document.getElementById('lang-en').removeAttribute('data-active');
    storage.setItem('language', 'french');
  } else {
    window.Transifex.live.translateTo('en', true);
    document.getElementById('lang-fr').removeAttribute('data-active');
    document.getElementById('lang-en').setAttribute('data-active', true);
    storage.setItem('language', 'english');
  }
}

window.onload = () => {
  window.Transifex.live.onReady(() => {
    let language = null;
    if (storage.getItem('language')) {
      if (storage.getItem('language') === 'french') {
        language = 'fr-CA';
      } else {
        language = 'en-CA';
      }
    } else if (navigator.language === 'fr-CA' || navigator.language === 'fr') {
      language = 'fr-CA';
    } else {
      language = 'en-CA';
    }
    setLanguage(language);

    const languageToggle = document.getElementById('language-toggle');
    languageToggle.onclick = (event) => {
      event.preventDefault();
      language = language === 'en-CA' ? 'fr-CA' : 'en-CA';
      setLanguage(language);
    };
  });


  const contactUsButton = document.getElementById('contact-us-button');

  if (contactUsButton) {
    contactUsButton.onclick = (event) => {
      event.preventDefault();
      window.Intercom('show');
    };
  }
};
